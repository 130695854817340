.WhoWeAreSection {
  // max-width: 100% !important;

  h1 {
    margin: 60px 0px;
    color: black;
  }
  p {
    font-size: 16px;
    color: black;
    font-weight: 400;
    font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
  }
}
