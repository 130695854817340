:root {
	--primary: #0f7542;
}

.spinners {
	margin: 20px auto;
}
.spinners .wrapper {
	height: 50px;
	padding: 10px 0;
	text-align: center;
}

/* ============================ */
/* SPINNER GENERAL              */
/* ============================ */
.spinner,
.spinner:before,
.spinner:after {
	width: 7px;
	height: 30px;
	background-color: var(--primary);
	border-radius: 2px;
}

.spinner {
	display: inline-block;
	position: relative;
}
.spinner:before,
.spinner:after {
	content: "";
	position: absolute;
	display: block;
	top: 0px;
}
.spinner:before {
	left: -10px;
}
.spinner:after {
	left: 10px;
}

/* ============================ */
/* SPINNER BLINK                */
/* ============================ */
@-webkit-keyframes glow {
	0% {
		background-color: transparent;
	}
	50% {
		background-color: var(--primary);
	}
	100% {
		background-color: transparent;
	}
}
@keyframes glow {
	0% {
		background-color: transparent;
	}
	50% {
		background-color: var(--primary);
	}
	100% {
		background-color: transparent;
	}
}
.spinner-blink {
	-webkit-animation: glow 0.6s 0.1s infinite;
	animation: glow 0.6s 0.1s infinite;
}
.spinner-blink:before {
	-webkit-animation: glow 0.6s 0s infinite;
	animation: glow 0.6s 0s infinite;
}
.spinner-blink:after {
	-webkit-animation: glow 0.6s 0.2s infinite;
	animation: glow 0.6s 0.2s infinite;
}
